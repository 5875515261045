<template>
  <div>
    <div class="qr-code-tips-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="qr-tips-occupancy text-center">
              <template v-if="work.km">
                <p class="font-header" style="font-size: 18px;">
                  {{ title.name_km }}{{ work.km }}
                </p>
              </template>
              <template v-if="work.en">
                <p class="font-en" style="font-size: 18px;">
                  {{ title.name_en }} {{ work.en }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row" v-if="architecture">
        <div class="col-md-12 margin-25">
          <table class="table table-bordered" style="vertical-align: middle">
            <tbody>
            <template>
              <tr class="bg-color-gray">
                <td width="330px;">
                  <p>គោត្តនាម និងនាម</p>
                </td>
                <td>
                  <p>{{ architecture.name_km }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td>
                  <p>Surname and Name</p>
                </td>
                <td>
                  <p>{{ architecture.name_en }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td><p>ភេទ</p></td>
                <td>
                  <p v-if="architecture.gender">{{ architecture.gender.name_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td><p>Gender</p></td>
                <td>
                  <p v-if="architecture.gender">{{ architecture.gender.name_en || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>លេខបញ្ជី</p></td>
                <td>
                  <p v-if="architecture.registration_number">{{ architecture.registration_number || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                  <p v-if="architecture.registration_expired_date">
                    ចុះ{{ $moment(architecture.registration_expired_date).locale('km').format(date_format_km) }}
                  </p>
                  <p v-else>
                    {{ $t('string.na') }}
                  </p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Registration Number</p></td>
                <td>
                  <p v-if="architecture.registration_number">{{ architecture.registration_number || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                  <p v-if="architecture.registration_expired_date">
                    Date: {{ $moment(architecture.registration_expired_date).locale('en').format(date_format_en) }}
                  </p>
                  <p v-else>
                    {{ $t('string.na') }}
                  </p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td>
                  <p>សិក្សា</p>
                </td>
                <td>
                  <template>
                    <p  v-if="work.km">
                      {{work.km || $t('string.na') }}
                    </p>
                  <p v-else> {{ $t('string.na') }}</p>
                  </template>
                  <template>
                    <p v-if="architecture.architecture_type">
                      ប្រភេទ: {{ architecture.architecture_type.name_km || $t('string.na') }}
                    </p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td>
                  <p>Work</p>
                </td>
                <td>
                  <template>
                    <p  v-if="work.en">
                      {{ work.en || $t('string.na') }}
                    </p>
                    <p v-else> {{ $t('string.na') }}</p>
                  </template>
                  <template>
                    <p v-if="architecture.architecture_type">
                      Class: {{ architecture.architecture_type.name_en || $t('string.na') }}
                    </p>
                    <p v-else>{{ $t('string.na') }}</p>
                  </template>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>សញ្ញាប័ត្រ</p></td>
                <td>
                  <p v-if="architecture.degree">{{ architecture.degree.name_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Degree</p></td>
                <td>
                  <p v-if="architecture.degree">{{ architecture.degree.name_en || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td><p>សិក្សានៅ</p></td>
                <td>
                  <p v-if="architecture.university">{{ architecture.university.name_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td><p>Study At</p></td>
                <td>
                  <p v-if="architecture.university">{{ architecture.university.name_en || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>លេខអត្តសញ្ញាណប័ណ្ណ</p></td>
                <td>
                  <p v-if="architecture.national_id_card_km">{{ architecture.national_id_card_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>National ID Card</p></td>
                <td>
                  <p v-if="architecture.university">{{ architecture.national_id_card || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td><p>សញ្ជាតិ</p></td>
                <td>
                  <p v-if="architecture.nationality">{{ architecture.nationality.name_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td><p>Nationality</p></td>
                <td>
                  <p v-if="architecture.nationality">{{ architecture.nationality.name_en || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>លេខទូរស័ព្ទ</p></td>
                <td>
                  <p v-if="architecture.phone_km">{{ architecture.phone_km || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Phone Number</p></td>
                <td>
                  <p v-if="architecture.phone">{{ architecture.phone || $t('string.na') }}</p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-white">
                <td><p>អ៊ីម៉ែល</p></td>
                <td>
                  <p v-if="architecture.email">
                    <a :href="`mailto:${architecture.email}`" target="_blank">{{architecture.email}}</a>
                  </p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-white">
                <td><p>E-mail</p></td>
                <td>
                  <p v-if="architecture.email">
                    <a :href="`mailto:${architecture.email}`" target="_blank">{{architecture.email}}</a>
                  </p>
                  <p v-else>{{ $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            <template>
              <tr class="bg-color-gray">
                <td><p>អាសយដ្ឋាន</p></td>
                <td>
                  <p>{{ architecture.full_address_km || $t('string.na') }}</p>
                </td>
              </tr>
              <tr class="bg-color-gray">
                <td><p>Address</p></td>
                <td>
                  <p>{{ architecture.full_address_en || $t('string.na') }}</p>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchitectureDetail',
  data () {
    return {
      architecture: null,
      date_format_km: 'ថ្ងៃទីDD ខែMMM ឆ្នាំYYYY',
      date_format_en: 'DD MMMM YYYY',
      title: {
        name_en: 'Certificate Of ',
        name_km: 'វិញ្ញាបនបត្រសិក្សា'
      },
      work: {
        km: 'Design',
        en: 'គម្រោងប្លង់'
      }
    }
  },
  methods: {
    getArchitecture () {
      this.$isLoading(true)
      this.$axios.get(this.$base_api + '/roubavontbokkol/show/' + this.$route.params.qr_code)
        .then((res) => {
          this.architecture = res.data.data
          if (this.architecture) {
            if (this.architecture.architecture_type) {
              if (this.architecture.architecture_type.name_en.toLowerCase() === 'architect') {
                this.work = {
                  km: 'គម្រោងប្លង់ស្ថាបត្យកម្ម',
                  en: 'Architectural Design'
                }
              } else {
                this.work = {
                  km: 'គម្រោងប្លង់គ្រឿងផ្គុំសំណង់',
                  en: 'Structural Design'
                }
              }
            }
          }
        }).finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getArchitecture()
  }
}
</script>

<style scoped>
@import "./../../../assets/css/customeTable.css";

.label-title {
  font-size: 38px;
}

.qr-code-tips-container {
  background-color: #3c8dbc;
  padding: 5px;
  width: 100%;
}

.qr-tips-occupancy {
  width: 100%;
  color: #ffffff;
  padding-top: 10px;
  font-size: 22px;
}
</style>
