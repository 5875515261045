<template>
  <architecture-detail/>
</template>

<script>
import ArchitectureDetail from '@/views/company/includes/ArchitectureDetail'
export default {
  name: 'Design',
  metaInfo () {
    return {
      title: 'Certificate',
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { ArchitectureDetail }
}
</script>

<style scoped>

</style>
